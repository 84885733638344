<template>
    <v-snackbar
        v-model="snackbar"
        :color="color"
        :timeout="timeout"
        shaped
        bottom
        dark
    >
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn
                dark
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>
  
<script>
import { get, sync } from 'vuex-pathify'


export default {
    name: 'DefaultMessages',
    data () {
        return {
            
        }
    },
    computed: {
        ...sync('messages', [
            'snackbar',
            'message',
            'color',
        ]),
        ...get('messages', [
            'timeout'
        ])
    }
}
</script>
  