<template>
  <v-footer
    id="default-footer"
    color="transparent"
    absolute
    app
    inset
  >
    <links />

    <Messages />
  </v-footer>
</template>

<script>
  // Components
  import Links from '@/components/page/Links'
  import Messages from '@/components/dashboard/widgets/Messages'

  export default {
    name: 'DefaultFooter',

    components: { Links, Messages },
  }
</script>
